import { gql } from '@apollo/client';

const SIGN_OUT = gql`
  mutation signOut {
    signOut {
      message
    }
  }
`;

const GET_USER = gql`
  query me {
    me {
      id
      email
      username
      avatar
      permissions
      resources
    }
    getResources(owner: "me") {
      count
    }
  }
`;

export { SIGN_OUT, GET_USER };
