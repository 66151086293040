import SearchIcon from '@mui/icons-material/Search';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Link from 'next/link';
import HeaderUser from './header/HeaderUser';

function Header(props) {
  const { t } = useTranslation('header');

  return (
    <header className="sticky w-100 z-10 dnav bg-th-navbar text-white">
      <nav>
        <ul className="flex h-16">
          <li className="my-auto">
            <Link href="/">
              <a className="ml-5 whitespace-nowrap flex">
                <Image className="" alt="Logo" src="https://static.plajer.xyz/feedbacky/img/projects/logos/laserflare.png" width={40} height={40} />
                <p aria-label="home page" className="invisible w-0 sm:visible sm:w-auto text-3xl ml-2 my-auto">
                  Marketplace
                </p>
                <p aria-label="home page" className="sm:hidden text-3xl -ml-2 my-auto">
                  M
                </p>
              </a>
            </Link>
          </li>

          <li className="my-auto ml-4">
            <Link href="/resources">
              <a className="text-xl">Resources</a>
            </Link>
          </li>

          <li className="my-auto ml-2">
            <button aria-label="Search" type="button" className="text-th-accent-light" onClick={() => props.setShowSearchModal(true)}>
              <SearchIcon />
            </button>
          </li>

          {process.browser && window !== undefined && (
            <li className="mr-5 ml-auto">
              <div className="items-center content-center h-full flex">
                <HeaderUser t={t} themeName={props.themeName} theme={props.theme} setTheme={props.setTheme} />
              </div>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
