import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { useQuery } from '@apollo/client';
import Skeleton from '@mui/material/Skeleton';
import { AnimatePresence } from 'framer-motion';
import { GET_USER } from './queries';
import Dropdown from './dropdown';

const ThemeChanger = dynamic(() => import('../ThemeChanger.tsx'));
const LoginModal = dynamic(() => import('../LoginModal'));
const MDiv = dynamic(() => import('../mdiv'));

export default function HeaderUser({ t, theme, setTheme }) {
  const [showLogin, setShowLogin] = useState(false);
  const [, rerender] = useState();

  const { data, loading, refetch } = useQuery(GET_USER, { fetchPolicy: 'cache-first' });

  const updateUser = () => {
    console.log('Refetch user & update UI');
    refetch()
      .then(() => {
        rerender(Math.random());
      })
      .catch((err) => {
        if (err.message !== 'Unauthenticated') {
          console.log(err);
        }
        rerender(Math.random());
      });
  };

  const [closeAuthWindow, setCloseAuthWindow] = useState(false);

  const startAuth = () => {
    console.log('Listening to auth complete.');
    const interval = setInterval(() => {
      if (window.localStorage.getItem('authenticated')) {
        setTimeout(() => refetch(), 100);
        window.localStorage.removeItem('authenticated');
        setCloseAuthWindow(true);
        setShowLogin(false);
        clearInterval(interval);
        console.log('Authenticated. Stop listening.');
      }
    }, 250);
  };

  if (loading && !data?.me)
    return (
      <div className="flex">
        <Skeleton variant="circular" height={36} width={36} />
        <Skeleton variant="text" className="ml-2 w-24" />
      </div>
    );
  return data?.me && !loading ? (
    <Dropdown data={data} theme={theme} setTheme={setTheme} updateUser={updateUser} />
  ) : (
    <>
      <AnimatePresence>
        {showLogin && (
          <MDiv transition={{ duration: 0.2 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <LoginModal t={t} disable={() => setShowLogin(false)} startAuth={() => startAuth()} close={closeAuthWindow} rerender={updateUser} />
          </MDiv>
        )}
      </AnimatePresence>
      <ThemeChanger theme={theme} selectTheme={setTheme} showLabel={false} onDark nativeSelect />
      <button
        type="button"
        onClick={
          () => {
            setShowLogin(!showLogin);
          } /* handleAuthenticaton */
        }
        className="mx-2 my-auto">
        <div className="my-auto">
          <span className="font-extrabold my-auto cursor-pointer">{t('auth.guest.login')}</span>
        </div>
      </button>
    </>
  );
}
