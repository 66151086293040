import React, { useState } from 'react';
import Link from 'next/link';
import { Paper, ClickAwayListener } from '@mui/material';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import Skeleton from '@mui/material/Skeleton';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { SIGN_OUT } from './queries';
import MDiv from '../mdiv';

const ThemeChanger = dynamic(() => import('../ThemeChanger'));

export default function Dropdown({ data, theme, setTheme, updateUser }) {
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const [showProfileDropdown, setProfileDropdown] = useState(false);
  const [signOut] = useMutation(SIGN_OUT, {
    onCompleted: () => {
      setProfileDropdown(false);
    },
  });
  const router = useRouter();
  const { t } = useTranslation('header');

  return (
    <ClickAwayListener
      onClickAway={() => {
        setProfileDropdown(false);
      }}>
      <div className="relative">
        <button
          type="button"
          className="mx-2 h-full highlight flex flex-nowrap cursor-pointer"
          onClick={() => {
            setProfileDropdown(!showProfileDropdown);
          }}>
          {!avatarLoaded && <Skeleton variant="circular" height={36} width={36} />}
          <img className={`my-auto rounded-full select-none ${avatarLoaded ? 'h-9 w-9' : 'h-0 w-0'}`} src={data?.me?.avatar || `https://avatars.dicebear.com/api/jdenticon/${data?.me?.id || 'null'}.svg?m=9`} alt="" onLoad={() => setAvatarLoaded(true)} />
          <span className="ml-2 cursor-pointer my-auto select-none">{data.me.username || data.me.email}</span>
        </button>
        <MDiv transition={{ duration: 0.3 }} initial="hidden" animate={showProfileDropdown ? 'visible' : 'hidden'} variants={{ hidden: { opacity: 0, scale: 0, y: -10, x: 10 }, visible: { opacity: 1, scale: [1, 1.1, 1] } }}>
          <Paper className="absolute flex flex-col bg-th-card border-purple-800 border-2 dtext z-30 overflow-hidden right-1 mt-2 divide-y-2 divide-purple-800 divide-dashed" style={{ width: '150%' }}>
            <button
              type="button"
              className="p-2 highlight"
              onClick={() => {
                router.push('/profile').then().catch();
                setProfileDropdown(false);
              }}>
              {t('profile.title')}
            </button>
            {data?.getResources?.count > 0 && (
              <div className="text-center p-2">
                <Link href="/dashboard/resources">
                  <a>My resources</a>
                </Link>
              </div>
            )}
            {data.me.permissions.length > 0 && (
              <>
                {data.me.permissions.includes('admin') && (
                  <div className="text-center mx-auto">
                    <Link href="/admin">
                      <a>Admin</a>
                    </Link>
                  </div>
                )}
                {data.me.permissions.includes('shop-owner') && (
                  <div className="text-center p-2">
                    <Link href="/cp">
                      <a>CP</a>
                    </Link>
                  </div>
                )}
              </>
            )}
            <div className="p-2 highlight">
              <ThemeChanger theme={theme} selectTheme={setTheme} />
            </div>
            <button
              type="button"
              className="p-2 highlight"
              onClick={() => {
                router.push('/settings').then().catch();
                setProfileDropdown(false);
              }}>
              Settings
            </button>
            <button
              type="button"
              className="p-2 cursor-pointer"
              onClick={() => {
                signOut().then(updateUser).catch();
              }}>
              {t('profile.sign_out')}
            </button>
          </Paper>
        </MDiv>
      </div>
    </ClickAwayListener>
  );
}
